import Pagination from "@/components/shared/Pagination";

const PaginatedTable = ({
  data = [],
  columns = [],
  renderRow = () => {},
  paginationData,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  isPageRangeEnabled = true,
  className = "card",
  tableClassName = "table table-striped table-outline table-vcenter text-nowrap table-sm card-table",
  isLoading = false
}) => {
  const totalPages = paginationData.total_pages || 1;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  return (
    <div className={className}>
      <table className={tableClassName}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={column.className}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length} className="text-center py-2">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">{I18n.t("shared.actions.loading")}</span>
                </div>
              </td>
            </tr>
          ) : (
            data.map((item, index) => renderRow(item, index))
          )}
        </tbody>
      </table>

      <div className="ms-2">
        <Pagination
          isPageRangeEnabled={isPageRangeEnabled}
          currentPage={currentPage}
          onCurrentPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          initialPerPage={perPage}
          pageCount={totalPages}
          perPage={perPage}
        />
      </div>
    </div>
  );
};

export default PaginatedTable;
