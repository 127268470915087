import parse from "html-react-parser";

import dayjs from "@/lib/dayjs";

const formatDate = (date) => {
  return dayjs(date).format(I18n.t("js.formats.default.date"));
};

export const getMessages = (series, lastUpdatedDate, willBeCreatedInDate, nextAnalysisDateFinal) => {
  let message = null;

  if (series.length === 0 && !lastUpdatedDate) {
    message = parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.will_be_created_in_html", {
      date: formatDate(willBeCreatedInDate),
    }));
  } else if (series.length === 0) {
    message = parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.will_be_displayed_html"));
  }

  let updatedAtMessage, nextAnalysisMessage;

  if (!lastUpdatedDate) {
    updatedAtMessage = parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.updated_at_empty_html"));
    nextAnalysisMessage = parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.next_analysis_html", {
      date: formatDate(nextAnalysisDateFinal),
    }));
  } else {
    updatedAtMessage = parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.updated_at_html", {
      date: formatDate(lastUpdatedDate),
    }));
    nextAnalysisMessage = parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.next_analysis_html", {
      date: formatDate(nextAnalysisDateFinal),
    }));
  }

  return { message, updatedAtMessage, nextAnalysisMessage };
};
