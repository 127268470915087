import parse from "html-react-parser";

const FaqAlert = ({message}) => {
  return (
    <div className="alert alert-warning mb-3">
      <div className="d-flex align-items-center">
        <i className="ti ti-exclamation-circle icon alert-icon" />

        <div className="text-body">
            {message}
        </div>
      </div>
    </div>
  );
};

export default FaqAlert;
