import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import DonutChart from "../DonutChart";
import FaqAlert from "./components/FaqAlert";
import { getMessages } from "./utils";

import dayjs from "@/lib/dayjs";

const FrequentlyAskedQuestions = ({
  lastUpdated,
  isLoadingDonutCharts,
  createdAt,
  currentWithContext,
  currentWithoutContext,
  hasScriptsSelected,
  assistantId
}) => {
  const series = currentWithContext?.series || [];
  const today = dayjs();

  let willBeCreatedIn = dayjs(createdAt).add(7, "day");
  if (willBeCreatedIn.isBefore(today, "day")) {
    willBeCreatedIn = today.add(1, "day");
  }

  let nextAnalysisDate;
  if (!lastUpdated) {
    nextAnalysisDate = willBeCreatedIn.add(7, "day");
  } else {
    nextAnalysisDate = dayjs(lastUpdated).add(7, "day");
  }

  if (nextAnalysisDate.isBefore(today, "day")) {
    nextAnalysisDate = today.add(1, "day");
  }

  if (!hasScriptsSelected) {
    const renderNoActivationAlert = () => {
      return (
        <>
        {I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_activation_alert.message_1")}
          <a
            href={`/ai/leadster/assistants/${assistantId}/activation`}
            target="_blank"
            rel="noopener noreferrer"
          >
          {parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_activation_alert.message_2_html"))} <i className="ti ti-external-link"/>
          </a>
        </>
      );
    };

    return <FaqAlert message={renderNoActivationAlert()} />;
  }

  const { message, updatedAtMessage, nextAnalysisMessage } = getMessages(series, lastUpdated, willBeCreatedIn, nextAnalysisDate);

  return (
    <>
      {message && <FaqAlert message={message} />}

      <div className="mt-3">
        <p>{updatedAtMessage}</p>
      </div>

      <Row>
        <Col>
          <DonutChart
            title={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.with_context.title")}
            subtitle={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.with_context.subtitle")}
            isLoading={isLoadingDonutCharts}
            data={currentWithContext}
          />
        </Col>
        <Col>
          <DonutChart
            title={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_context.title")}
            subtitle={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_context.subtitle")}
            isLoading={isLoadingDonutCharts}
            data={currentWithoutContext}
          />
        </Col>
      </Row>

      <div className="mt-3">
        <p>{nextAnalysisMessage}</p>
      </div>
    </>
  );
};

export default FrequentlyAskedQuestions;
