import { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import * as S from "./styles";

const CustomTabs = ({ tabsConfig, defaultActiveKey, tabParamName = "tab" }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get(tabParamName) || defaultActiveKey;
    setActiveKey(tab);
  }, [location.search]);

  const handleSelect = (key) => {
    setActiveKey(key);
    const query = new URLSearchParams(location.search);
    query.set(tabParamName, key);
    history.replace({ search: query.toString() });
  };

  return (
    <S.Tabs
      activeKey={activeKey}
      onSelect={handleSelect}
      id="custom-tabs"
      className="mb-2 border border-0"
    >
      {tabsConfig.map(({ eventKey, title, iconClassName, content }) => (
        <Tab
          eventKey={eventKey}
          key={eventKey}
          title={
            <span>
              {iconClassName && <i className={`icon ${iconClassName} text-primary me-2`} />}
              {title}
            </span>
          }
        >
          <div className="pt-3">{content}</div>
        </Tab>
      ))}
    </S.Tabs>
  );
};

export default CustomTabs;
