import axios from "@/lib/axios";

export async function fetchLinkedScripts({ assistantId }) {
  let isLoading = true;
  let linkedScripts = [];

  try {
    const response = await axios.get(`/ai/scripts/assistant-scripts/${assistantId}`);

    linkedScripts = response.data.scripts;
  }
  catch (err) {}
  finally { isLoading = false; }

  return { linkedScripts, isLoading };
};

export async function fetchMessage({ page, scripts, conversation, evaluationConversation, assistantId }) {
  let isLoading = true;
  let messages = [];

  try {
    if (scripts.length == 0) throw new Error("No script or conversation defined");

    const response = await axios.get("/ai/history_tracker", {
      params: { ai_assistant_id: assistantId, page: page || 1, script_id: scripts, status: conversation, evaluation_conversation: evaluationConversation }
    });

    messages = response.data.history_trackers;
  }
  catch (err) {}
  finally { isLoading = false; }

  return { messages, isLoading };
};

export async function fetchMessageHistory({ selectedMessage, page, scripts, conversation, evaluationConversation, assistantId }) {
  let isLoading = true;
  let messagesHistory = [];

  try {
    if (!selectedMessage) throw new Error("No message selected");

    const response = await axios.get(`/ai/history_tracker/${selectedMessage}`, {
      params: { ai_assistant_id: assistantId, page: page || 1, script_id: scripts, status: conversation, evaluation_conversation: evaluationConversation }
    });

    messagesHistory = response.data.history_trackers;
  }
  catch (err) {}
  finally { isLoading = false; }

  return { messagesHistory, isLoading };
};

export async function fetchAssistantAnalytics({ assistantId, scriptIds, startDate, endDate }) {
  let isLoading = true;
  let analytics = {};

  try {
    if (scriptIds.length == 0) throw new Error("No script or conversation defined");

    const response = await axios.get(`/ai/assistants/analytics/${assistantId}`, {
      params: { script_ids: scriptIds, start_date: startDate, end_date: endDate }
    });
    analytics = response.data;
  }
  catch (err) {
    const i18n_scope = "services.ai.process_assistant_analytics"
    analytics = {
      total_visitors: {
        title: 0,
        description: I18n.t(`${i18n_scope}.total_visitors`),
      },
      average_duration: {
        title: I18n.t(`${i18n_scope}.duration`, { min: 0, sec: 0 }),
        description: I18n.t(`${i18n_scope}.average_duration`),
      },
      average_questions: {
        title: 0,
        description: I18n.t(`${i18n_scope}.average_questions`),
      },
      total_questions: {
        title: 0,
        description: I18n.t(`${i18n_scope}.total_questions`),
      },
      answered_questions: {
        title: "0% (0)",
        description: I18n.t(`${i18n_scope}.answered_questions`),
      },
      unanswered_questions: {
        title: "0% (0)",
        description: I18n.t(`${i18n_scope}.unanswered_questions`),
      },
    }
  }
  finally { isLoading = false; }

  return { analytics, isLoading };
};

export async function fetchSummaryQuestions({ assistantId }) {
  let isLoading = true;
  let summaryQuestions = {};

  try {
    const response = await axios.get(`/ai/assistants/summary_questions/${assistantId}`);
    summaryQuestions = response.data.summary_questions;
  }
  catch (err) {}
  finally { isLoading = false; }

  return { summaryQuestions, isLoading };
};
