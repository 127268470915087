import { Tabs as ReactBootstrapTabs } from "react-bootstrap";
import styled from "styled-components";

export const Tabs = styled(ReactBootstrapTabs)`
  .nav-link {
    color: rgba(var(--tblr-gray-600));

    &:hover {
      color: rgba(var(--tblr-primary-rgb));
      border: none;
    }

    &.active {
      background-color: rgba(var(--tblr-primary-rgb), 0.1);
      color: rgba(var(--tblr-primary-rgb));
      border: none;
      border-radius: 0.375rem;
      position: relative;
      margin-bottom: 2px;

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        background-color: rgba(var(--tblr-primary-rgb));
        border-radius: 1px;
      }
    }
  }
`;
